import React from "react";
import "./Projects.scss";
import { HTMLContent } from '../Content';

const Projects = ({projects} ) => {
    return (
        <div id="projects" className="container">
            <section>
                <div className="columns">
                    <div className="column is-12">
                        <div >
                            <div>
                              <h1 className="h1-title">NOSSOS PROJETOS</h1>
                              <h3 className="h3-subtitle">
                                Acreditamos em ideias e em pessoas. <br></br> Investimos para desenvolver projetos e líderes.
                              </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <article className="projects columns is-centered">
                    {projects.map((project) => (
                        <div className="project column is-parent">
                            <p className="project__title has-text-weight-bold is-uppercase">{project.title}</p>
                            <div className="project__description">
                                <HTMLContent content={project.description}></HTMLContent>
                            </div>
                            <a className="button is-link" href={project.link.page}>{project.link.text}</a>
                        </div>
                    ))}
                </article>
            </section>
        </div>
    );
}

Projects.propTypes = {};

export default Projects;
