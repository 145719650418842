import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

import Donate from '../components/donation/Donate';
import History from '../components/home/History';
import HowItWorks from '../components/home/HowItWorks';
import Impact from '../components/home/Impact';
import Projects from '../components/home/Projects';
import BlogPostsSection from '../components/blog/BlogPostsSection';
import Testimonials from '../components/home/Testimonials';
import Banner from '../components/shared/Banner';

export const IndexPageTemplate = ({ banner, howitworks, impact, projects }) => (
  <div>
    <Banner {...banner} />
    <HowItWorks {...howitworks} />
    <Impact {...impact} />
    <Projects projects={projects} />
    <History />
    <Donate />
    <Testimonials />
    <BlogPostsSection title="FIQUE POR DENTRO" />
  </div>
);

IndexPageTemplate.propTypes = {
  banner: PropTypes.object,
  howitworks: PropTypes.object,
  impact: PropTypes.object,
  projects: PropTypes.array
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        banner={frontmatter.banner}
        howitworks={frontmatter.howitworks}
        impact={frontmatter.impact}
        projects={frontmatter.projects} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        banner {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
        howitworks {
          title
          description
          cards {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
        }
        impact {
          projects
          students
        }
        projects {
          title
          link {
            text
            page
          }
          description
        }
      }
    }
  }
`;
