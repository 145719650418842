import React from "react";
import "./Impact.scss";
import PropTypes from "prop-types";

const Impact = ({ projects, students }) => {
    return (
        <div id="impact">
            <section>
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div >
                            <div>
                                <div className="h1-title">
                                    <h1 className="h1-title">NOSSO IMPACTO</h1>
                                </div>
                                <p className="text has-text-centered is-size-5">Fomentamos a inovação e a educação de ponta na Poli-USP.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rates columns is-centered" style={{
height:250
                }}>
                    <div className="rate column is-parent">
                        <div className="rate__info">
                            <p className="rate__title has-text-weight-semibold is-uppercase">{projects.toString()}</p>
                            <div className="rate__description">
                                <p>Projetos apoiados</p>
                            </div>
                        </div>
                    </div>
                    <div className="rate column is-parent">
                        <div className="rate__info">
                            <p className="rate__title has-text-weight-semibold is-uppercase">{students}</p>
                            <div className="rate__description">
                                <p>Alunos impactados</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

Impact.propTypes = {
    projects: PropTypes.number,
    students: PropTypes.number

}

export default Impact;