import React from "react";
import "./History.scss";

const History = () => {
    return (
        <div id="history">
            <section>
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div >
                            <div>
                                <div className="h1-title">
                                    <h1 className="h1-title">NOSSA HISTÓRIA</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-vertical cta">
                    <div className="cta__text">
                        <p className="has-text-centered history-text">
                          Somos o primeiro e maior fundo patrimonial ligado a uma
                          universidade no país. Começamos em 2011 e já estamos fazendo
                          história pelo ensino superior no Brasil.
                        </p>
                    </div>
                    <div className="columns cta__column">
                        <div className="cta__button">
                            <a className="button is-link--red" href="/about">saiba mais</a>
                        </div>
                        <div className="cta__button">
                            <a href="https://forms.gle/pKq5YYY6LJqoB9r38" target="_black" className="button is-link--red">
                            quero ser voluntária(o)!
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

History.propTypes = {};

export default History;
