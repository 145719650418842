import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import './Testimonials.scss';

const Testimonials = ({ data }) => {
  const { edges: testimonials } = data.allTestimonialsJson;
  console.log({ data, testimonials });
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div id="testimonials">
      <section>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div>
              <main>
                <div className="h1-title">
                  <h1 className="h1-title">Depoimentos</h1>
                </div>
                <div className="arrows-container"></div>
                <div>
                  <Slider {...sliderSettings}>
                    {testimonials &&
                      testimonials.map(({ node: testimonial }) => (
                        <div key={testimonial._id}>
                          <p className="description">{testimonial.description}</p>
                          <div className="author">
                            <div className="author__image" style={{}}>
                              {testimonial.authorImage ? (
                                <PreviewCompatibleImage
                                  imageInfo={{
                                    image: testimonial.authorImage,
                                    alt: `image thumbnail for ${testimonial.authorName}`,
                                  }}
                                />
                              ) : null}
                            </div>
                            <div className="author__info">
                              <div>{testimonial.authorName}</div>
                              <div>{testimonial.authorDescription}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </main>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Testimonials.propTypes = {
  data: PropTypes.shape({
    allTestimonialsJson: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query TestimonialsQuery {
        allTestimonialsJson {
          edges {
            node {
              id
              description
              authorName
              authorImage
              authorDescription
            }
          }
        }
      }
    `}
    render={(data) => <Testimonials data={data} />}
  />
);
