import React from "react";
import PropTypes from "prop-types";
import getImage from '../../utils/getImage';

import './HowItWorks.scss';

const HowItWorks = ({ title, description, cards }) => {
    return (
        <div id="how-it-works" className="container">
            <div className="section">
                <div className="columns">
                    <div className="column is-12">
                        <div >
                            <div>
                                <div className="h1-title">
                                    <h1 className="h1-title">{title}</h1>
                                </div>
                                <div className="h3-subtitle">
                                    <h3 className="h3-subtitle">{description}</h3>
                                </div>
                            </div>

                            <ul className="howItWorks">
                                {cards.map(card => (
                                    <li className="list-item">
                                        <img className="navbar-logo" src={getImage(card.image)} />
                                        <span>{card.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

HowItWorks.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    cards: PropTypes.arrayOf([{
        image: [PropTypes.object, PropTypes.string]
    }])
};

export default HowItWorks;
